<template>
  <div>
    <div class="user-detail-content">
      <div class="heading">
        <div class="left-heading text-start"><h2>Edit Project</h2></div>
      </div>
    </div>
    <div class="modal-edit-user-content">
      <div
        id="edit-user-modal"
        tabindex="-1"
        aria-labelledby="edit-user-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered dialog-edit-user">
          <div class="modal-content">
            <ValidationObserver v-slot="{ handleSubmit }">
              <div class="modal-body text-start">
                <div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">
                      Project name <span class="text-danger">*</span>
                    </div>
                    <div class="note">Project Name must be the same as Teams Channel</div>
                    <div class="col-12 input-group">
                      <ValidationProvider
                        name="Project name"
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        class="w-100"
                      >
                        <input
                          class="form-control"
                          type="text"
                          v-model="updateInfo.name"
                          placeholder="Project name"
                          @input="debouncedQuery"
                        />
                        <span class="error text-left f-w3">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                      <span
                        class="error text-left f-w3"
                        v-show="projectNotFound"
                      >
                        {{
                          `Not found Channel Teams named ${updateInfo.name}`
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 mb-2">
                      Division <span class="text-danger">*</span>
                    </div>
                    <div class="col-12 input-group">
                      <input
                        class="form-control"
                        type="text"
                        v-model="updateInfo.divisionName"
                        placeholder="Division"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">
                      Teams Group <span class="text-danger">*</span>
                    </div>
                    <div class="col-12 input-group">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Teams Group"
                        v-model="groupname"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">
                      Teams Channel <span class="text-danger">*</span>
                    </div>
                    <div class="col-12 input-group">
                      <select v-model="updateInfo.selectedChannelId">
                        <option disabled value="">Please select one</option>
                        <option
                          v-for="option in listChannels"
                          v-bind:key="option.channelId"
                          :value="option.channelId"
                        >
                          {{ option.channelName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">
                      Chatwork room url <span class="text-danger">*</span>
                    </div>
                    <div class="col-12 input-group">
                      <ValidationProvider
                        name="Chatwork room url"
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        class="w-100"
                      >
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Chatwork room url"
                          v-model="updateInfo.chatworkRoomUrl"
                          @input="debouncedQueryForChatWork"
                        />
                        <span class="error text-left f-w3">{{
                          errors[0]
                        }}</span>
                        <span
                          class="error text-left f-w3"
                          v-show="wrongChatworkID"
                        >
                          {{
                            `Not found Channel Teams named ${updateInfo.name}`
                          }}</span
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">Webhook URL</div>
                    <div class="col-12 input-group">
                      <input
                        class="form-control"
                        type="text"
                        :placeholder="hookLink"
                        v-model="hookLink"
                        disabled
                      />
                    </div>
                    <div class="mt-3">
                      <a
                        href="https://amelavn-my.sharepoint.com/:w:/r/personal/khanh_lethiminh_amela_vn/Documents/Microsoft%20Teams%20Chat%20Files/HDSD_ChatworkTool.docx?d=w41696b3dc8d1416092af2b0710344ad5&csf=1&web=1&e=NUgnxA"
                        style="float: right"
                        target="_blank"
                        >How to chatwork webhook</a
                      >
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">
                      Customer Account <span class="text-danger">*</span>
                    </div>
                    <div class="col-12 input-group">
                      <!-- <select v-model="updateInfo.selectedCustomer">
                        <option disabled value=''>Please select one</option>
                        <option v-for="option in listUserInChatWork" v-bind:key="option.id" :value="option.id">
                          {{ option.name }}
                        </option>
                      </select> -->
                      <v-select
                        v-model="updateInfo.selectedCustomer"
                        :options="listUserInChatWork"
                        multiple
                        :reduce="(name) => name.id"
                        label="name"
                        placeholder="Please select"
                      >
                      </v-select>
                      <span
                        class="error text-left f-w3 col-12"
                        v-show="showErrorNotSelectCustomer"
                      >
                        {{ `Let's select a Customer` }}</span
                      >
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">
                      PM Account <span class="text-danger">*</span>
                    </div>
                    <div class="col-12 input-group">
                      <!-- <select v-model="updateInfo.selectedPm">
                        <option disabled value="">Please select one</option>
                        <option
                          v-for="option in listUserInTeams"
                          v-bind:key="option.id"
                          :value="option.id"
                        >
                          {{ option.name }}
                        </option>
                      </select> -->
                      <v-select
                        v-model="updateInfo.selectedPm"
                        :options="listUserInTeams"
                        :reduce="(name) => name.id"
                        label="name"
                        placeholder="Please select one"
                      >
                      </v-select>
                      <span
                        class="error text-left f-w3 col-12"
                        v-show="showErrorNotSelectPM"
                      >
                        {{ `Let's select a PM` }}</span
                      >
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">BrSE/Comtor Account</div>
                    <div class="col-12 input-group">
                      <!-- <select v-model="updateInfo.selectedBrse">
                        <option value="">Please select one</option>
                        <option
                          v-for="option in listUserInTeams"
                          v-bind:key="option.id"
                          :value="option.id"
                        >
                          {{ option.name }}
                        </option>
                      </select> -->
                      <v-select
                        v-model="updateInfo.selectedBrse"
                        :options="listUserInTeams"
                        :reduce="(name) => name.id"
                        label="name"
                        placeholder="Please select one"
                      >
                      </v-select>
                      <span
                        class="error text-left f-w3 col-12"
                        v-show="showErrorNotSelectBrseComtor"
                      >
                        {{ `Let's select a BrSE/Comtor` }}</span
                      >
                    </div>
                  </div>
                  <div class="row mb-5 align-items-center">
                    <div class="col-3">Remind</div>
                    <div class="w-25 input-group">
                      <select v-model="updateInfo.timeMention">
                        <option
                          v-for="option in getTimeSelect()"
                          v-bind:key="option.id"
                          :value="option.id"
                        >
                          {{ option.data }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="button-group justify-content-center d-flex">
                    <button
                      type="button"
                      class="btn btn-warning text-white"
                      v-on:click.prevent.stop="handleSubmit(updateProject)"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { MESSAGES, MODAL } from "@/utils/const";

export default {
  name: "EditProject",
  components: {},
  data() {
    return {
      channelName: "",
      groupname: "",
      projectNotFound: false,
      wrongChatworkID: false,
      showErrorNotSelectPM: false,
      showErrorNotSelectCustomer: false,
      showErrorNotSelectBrseComtor: false,
      projectChanged: false,
      chatworkURLChanged: false,
      listChannels: [],
      listUserInChatWork: [],
      listUserInTeams: [],
      projectOld: {
        chatworkRoomUrl: "",
        selectedChannelId: "",
        divisionName: "",
        name: "",
        teamsGroupId: "",
        timeMention: "",
        selectedPm: "",
        selectedBrse: "",
      },
      updateInfo: {
        chatworkRoomUrl: "",
        selectedChannelId: "",
        divisionName: "",
        name: "",
        teamsGroupId: "",
        timeMention: "",
        selectedPm: "",
        selectedBrse: "",
        selectedCustomer: [],
      },
      hookLink: `${process.env.VUE_APP_MY_ENV_BASE_API_URL}/bots/webhook`,
    };
  },
  mounted() {},
  created() {
    this.getProjectDetail();
  },
  watch: {
    selectedPm() {
      this.showErrorNotSelectPM = false;
    },
    selectedCustomer() {
      this.showErrorNotSelectCustomer = false;
    },
    selectedBrse() {
      this.showErrorNotSelectBrseComtor = false;
    },
  },
  methods: {
    getTimeSelect() {
      return [
        { id: "15P", data: "00:15" },
        { id: "30P", data: "00:30" },
        { id: "1H", data: "01:00" },
        { id: "2H", data: "02:00" },
        { id: "4H", data: "04:00" },
        { id: "8H", data: "08:00" },
        { id: "12H", data: "12:00" },
        { id: "24H", data: "24:00" },
      ];
    },
    getProjectChannel() {
      this.projectNotFound = false;
      if (_.isEmpty(this.updateInfo.name)) {
        return;
      }
      this.$root.$refs.loading.start();
      let params = {
        name: this.updateInfo.name,
      };
      this.$store
        .dispatch("projects/getProjectChannels", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.listChannels = response;
        })
        .catch(() => {
          this.projectNotFound = true;
          this.$root.$refs.loading.finish();
        });
    },
    getGroupTeams() {
      this.projectNotFound = false;
      this.showErrorNotSelectBrseComtor = false;
      this.showErrorNotSelectPM = false;
      if (_.isEmpty(this.updateInfo.name)) {
        return;
      }
      var match = /(.*)-/.exec(this.updateInfo.name);
      if (!_.isEmpty(match)) {
        this.divisionName = match[1].trim();
      }
      this.$root.$refs.loading.start();
      let params = {
        name: this.updateInfo.name,
      };
      this.$store
        .dispatch("projects/getProjectTeams", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.channelName = response.channelName;
          this.groupname = response.groupname;
          if (this.projectChanged) {
            this.updateInfo.selectedChannelId = response.channelId;
          }
        })
        .catch(() => {
          this.projectNotFound = true;
          this.updateInfo.selectedChannelId = "";
          this.$root.$refs.loading.finish();
        });
      if (this.projectChanged) {
        this.updateInfo.selectedPm = "";
        this.updateInfo.selectedBrse = "";
      }
      this.$store
        .dispatch("user/getUserInTeams", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.listUserInTeams = response;
        })
        .catch(() => {
          this.listUserInTeams = [];
          this.updateInfo.selectedPm = "";
          this.updateInfo.selectedBrse = "";
          this.$root.$refs.loading.finish();
        });
    },
    getListUserInChatWork() {
      var match = /#!rid(.*)/.exec(this.updateInfo.chatworkRoomUrl);
      this.wrongChatworkID = false;
      this.showErrorNotSelectCustomer = false;
      if (_.isEmpty(match)) {
        if (this.updateInfo.chatworkRoomUrl !== "") {
          this.wrongChatworkID = true;
        }
        return;
      }
      this.$root.$refs.loading.start();
      let params = {
        id: match[1],
      };
      if (this.chatworkURLChanged) {
        this.updateInfo.selectedCustomer = [];
      }
      this.$store
        .dispatch("user/getUsersInChatWork", params)
        .then((response) => {
          this.listUserInChatWork = response;
          console.log("listUserInChatWork", this.listUserInChatWork);

          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.listUserInChatWork = [];
          this.updateInfo.selectedCustomer = [];
          this.wrongChatworkID = true;
          this.$root.$refs.loading.finish();
        });
    },
    getProjectDetail() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("projects/getProjectDetail", this.$route.params.id)
        .then((response) => {
          this.projectOld = response;
          this.projectOld.selectedChannelId = response.teamsChannelId;
          this.channelName = response.channelName;
          this.groupname = response.groupname;
          this.projectOld.selectedBrse = "";
          const userId = [];
          response.users.forEach((item) => {
            switch (item.role) {
              case "Customer":
                userId.push(item.id);
                break;
            }
          });
          response.users.forEach((item) => {
            switch (item.role) {
              case "Pm":
                this.projectOld.selectedPm = item.id;
                break;
              case "ComtorBrse":
                this.projectOld.selectedBrse = item.id;
                break;
              case "Customer":
                this.projectOld.selectedCustomer = userId;
                break;
            }
          });
          this.updateInfo = { ...this.projectOld };
          this.getGroupTeams();
          this.getListUserInChatWork();
          this.getProjectChannel();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    updateProject() {
      if (this.updateInfo.selectedCustomer === "") {
        this.showErrorNotSelectCustomer = true;
        return;
      } else if (this.updateInfo.selectedPm === "") {
        this.showErrorNotSelectPM = true;
        return;
      }
      if (this.updateInfo.divisionName !== this.projectOld.divisionName) {
        alert("Cant change project to other division");
        return;
      }
      const addUsers = [];
      const removeUsers = [];
      let listUserAdd = [];
      let listUserRemove = [];

      let updatInfoClone = [...this.updateInfo.selectedCustomer];
      let projectOldClone = [...this.projectOld.selectedCustomer];
      this.updateInfo.selectedCustomer.map((item) => {
        const checkInclude = this.projectOld.selectedCustomer.includes(item);
        if (checkInclude) {
          const index = projectOldClone.indexOf(item);
          const index2 = updatInfoClone.indexOf(item);
          projectOldClone.splice(index, 1);
          updatInfoClone.splice(index2, 1);
        }
      });
      if (
        this.updateInfo.selectedCustomer !== this.projectOld.selectedCustomer
      ) {
        if (updatInfoClone.length > 0) {
          listUserAdd = updatInfoClone.map((item, ind) => {
            return {
              userId: updatInfoClone[ind],
              role: "Customer",
            };
          });
          addUsers.push(...listUserAdd);
        }
        if (projectOldClone.length > 0) {
          listUserRemove = projectOldClone.map((item, ind) => {
            return {
              userId: projectOldClone[ind],
              role: "Customer",
            };
          });
          removeUsers.push(...listUserRemove);
        }
        // users.push({
        //   userId: this.updateInfo.selectedCustomer,
        //   role: "Customer",
        // });
      }
      if (this.updateInfo.selectedBrse !== this.projectOld.selectedBrse) {
        addUsers.push({
          userId:
            this.updateInfo.selectedBrse !== ""
              ? this.updateInfo.selectedBrse
              : null,
          role: "ComtorBrse",
        });
      }
      if (this.updateInfo.selectedPm !== this.projectOld.selectedPm) {
        addUsers.push({
          userId: this.updateInfo.selectedPm,
          role: "Pm",
        });
      }
      addUsers.map((item) => {
        const checkInclude = removeUsers.find(
          (itemRemove) => item.userId === itemRemove.userId
        );
        if (checkInclude) {
          const index = removeUsers.indexOf(
            item.userId && item.role === "Customer"
          );
          const index2 = addUsers.indexOf(
            item.userId && item.role === "Customer"
          );
          removeUsers.splice(index, 1);
          addUsers.splice(index2, 1);
        }
      });

      let body = {
        id: this.$route.params.id,
        projectName:
          this.updateInfo.name !== this.projectOld.name
            ? this.updateInfo.name
            : undefined,
        chatworkRoomUrl:
          this.updateInfo.chatworkRoomUrl !== this.projectOld.chatworkRoomUrl
            ? this.updateInfo.chatworkRoomUrl
            : undefined,
        teamsGroupId:
          this.updateInfo.teamsGroupId !== this.projectOld.teamsGroupId
            ? this.updateInfo.teamsGroupId
            : undefined,
        teamsChannelId:
          this.updateInfo.selectedChannelId !==
          this.projectOld.selectedChannelId
            ? this.updateInfo.selectedChannelId
            : undefined,
        timeMention:
          this.updateInfo.timeMention !== this.projectOld.timeMention
            ? this.updateInfo.timeMention
            : undefined,
        addUsers: !_.isEmpty(addUsers) ? addUsers : undefined,
        removeUsers: !_.isEmpty(removeUsers) ? removeUsers : undefined,
      };
      this.$store
        .dispatch("projects/updateProject", body)
        .then(() => {
          this.$toast(
            MESSAGES.UPDATE_PROJECT_SUCCESS,
            MODAL.MODAL_SUCCESS,
            MODAL.MODAL_TYPE_SUCCESS
          );
          this.$router.go(-1);
          this.$root.$refs.loading.finish();
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.$toast(
              MESSAGES.PROJECT_EXISTED,
              MODAL.MODAL_SUCCESS,
              MODAL.MODAL_TYPE_DANGER
            );
          } else {
            alert(error);
          }

          this.$root.$refs.loading.finish();
        });
    },
    debouncedQuery: _.debounce(function () {
      this.projectChanged = true;
      this.getGroupTeams();
      this.getProjectChannel();
    }, 1500),
    debouncedQueryForChatWork: _.debounce(function () {
      this.chatworkURLChanged = true;
      this.getListUserInChatWork();
    }, 1500),
  },
};
</script>

<style scoped lang="scss">
.noteProject {
  font-size: 13px;
}
@import "@/assets/scss/projects/create.scss";
</style>
