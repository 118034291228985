import moment from "moment";

export const MESSAGES = {
  NOTICE: "通知",
  REQUIRED: "は必須です。",
  SAVE_SUCCESS: "購入します。",
  SAVE_ERROR: "購入に失敗しました",
  DATE_ERROR: "は今より六ってなりません",
  CONFIRM_CANCEL: "このできてよろしいでます。",
  INTERVAL_ERROR: "エラーがししました。",
  CONFIRM_BLOCK_USER: 'このユーザーをブロックしてもよろしいですか？',
  CONFIRM_DELETE_USER_ACCOUNT: 'このユーザーを削除してもよろしいですか？',
  LOGIN_SUCCESS: 'ログインに成功しました',
  LOGIN_FAIL: 'ログインに失敗しました。',
  UPDATE_USER_SUCCESS: 'ユーザーの成功を更新します',
  UPDATE_USER_FAIL: 'ユーザーの更新に失敗しました',
  BLOCK_USER_SUCCESS: 'ユーザーの成功をブロックする',
  BLOCK_USER_FAIL: 'ユーザーのブロックに失敗しました',
  CONFIRM_DELETE_USER: 'このユーザーを削除してもよろしいですか？',
  DELETE_USER_SUCCESS: 'ユーザーの成功を削除します',
  DELETE_USER_FAIL: 'ユーザーの削除に失敗しました',
  CONFIRM_UNBLOCK_USER: 'このユーザーのブロックを解除してもよろしいですか？',
  CONFIRM_REMOVE_SETTING: 'この設定を削除してもよろしいですか？',
  UNBLOCK_USER_SUCCESS: 'ユーザーの成功のブロックを解除する',
  UNBLOCK_USER_FAIL: 'ユーザーのブロック解除に失敗しました',
  ERROR_PAYMENT_SETTING_1: '最終値は開始値より大きくなければなりません。',
  ERROR_PAYMENT_SETTING_2: '次の設定の開始値は、前の設定の最終値より大きくなければなりません',
  LOGOUT_SUCCESS: '正常にログアウトしました',
  CANNOT_GET_DATA: 'OOP！何かがうまくいかなかった！',
  CONFIRM_DELETE_ANNOUNCEMENT: 'こちらのアナウンスを削除してよろしいでしょうか？',
  DELETE_ANNOUNCEMENT_SUCCESS: 'アナウンスの削除に成功しました',
  DELETE_ANNOUNCEMENT_FAIL: 'アナウンスの削除に失敗しました',
  UPDATE_BANNER_SUCCESS: '編集情報が保存されます。',
  UPDATE_BANNER_FAIL: 'バナーの更新に失敗する',
  DELETE_USER_DETAIL_SUCCESS: 'ユーザーの成功を削除する',
  DELETE_USER_DETAIL_FAIL: 'ユーザーの削除に失敗しました',
  GIFT_POINT_FAIL: '失敗ギフトポイントを与える',
  CANCEL_EDIT_INFOMATION_AND_MOVE_PAGE: '情報をキャンセルしてもよろしいですか？',
  DELETE_BANNER_CONFIRM: 'このバナーを削除しますか？',
  PAYBACK_SUCCESS: '送金に成功',
  PAYBACK_FAIL: '送金に失敗しました',
  CREATE_ANNOUNCEMENT_SUCCESS: '新しい成功メッセージを作成する',
  CREATE_ANNOUNCEMENT_FAIL: '新しい失敗メッセージを作成する',
  UPDATE_ANNOUNCEMENT_SUCCESS: '成功メッセージの更新',
  UPDATE_ANNOUNCEMENT_FAIL: '通知を更新できません',
  UPDATE_PROJECT_SUCCESS: 'The project has been successfully updated',
  DELETE_PROJECT_SUCCESS: 'Project deleted successfully',
  CREATE_PROJECT_SUCCESS: 'Project created successfully',
  PROJECT_EXISTED: 'Project with name existed',
  ADD_PM_SUCCESSFUL: 'PM added successfully',
  DELETE_PM_SUCCESSFUL: 'PM deteted successfully',
  PM_EXISTED: "PM already exists in the Devision",
  RESOLVE_SUCCESS: 'Resolve successfully',
};


export const MODAL = {
  MODAL_SUCCESS: 'successfully',
  MODAL_TYPE_SUCCESS: 'success',
  MODAL_TYPE_DANGER: 'danger',
  CONFIRM_BLOCK_USER_TITLE: '確認してください',
  CONFIRM_DELETE_USER_ACCOUNT_TITLE: '確認してください',
  LABEL_OK: 'OK',
  LABEL_CANCEL: 'キャンセル',
  CONFIRM_DELETE_USER_TITLE: '確認してください',
  CONFIRM_UNBLOCK_USER_TITLE: '確認してください',
  CONFIRM_REMOVE_SETTING_TITLE: '確認してください',
  CONFIRM_DELETE_ANNOUNCEMENT_TITLE: '確認してください',
  NOTIFICATION: '通知',
  CONNECTION_NOT_FOUND: '接続が見つかりません',
  CONFIRM_TITLE_MODAL: '確認してください'
};

export const USER_TYPE_OPTIONS = [
  {value: 1, text: "ユーザータイプ"},
  {value: 'GUEST', text: "視聴者"},
  {value: 'DISTRIBUTE', text: "配信者"},
];

export const USER_STATUS_OPTIONS = [
  {value: 2, text: "ステータス"},
  {value: 0, text: "アクティブ"},
  {value: 1, text: "ブロック"},
];

export const USER_GENDER_OPTIONS = [
  {value: 0, text: "男性"},
  {value: 1, text: "女性"},
  {value: 2, text: "内緒"},
];

export const PER_PAGE_NUMBER = 10;

export const REPORT_STATUS_OPTIONS = [
  {value: 2, text: "ステータス"},
  {value: 'REPORT', text: "報告"},
  {value: 'BLOCK', text: "ブロック"},
];

export const SYSTEM_REVENUE_STATUS_OPTIONS = [
  {value: 2, text: "ステータス"},
  {value: 1, text: "成功"},
  {value: 0, text: "失敗"},
];

export const PAYMENT_MANAGEMENT_STATUS_OPTIONS = [
  {value: 4, text: "ステータス"},
  {value: 1, text: "成功"},
  {value: 2, text: "失敗"},
  {value: 0, text: "進行中"},
  {value: 3, text: "システムエラー"},
];

export const SETTING_DATE_RANGE_LOCALE = {
  direction: 'ltr',
  format: 'yyyy/mm/dd',
  separator: ' ~ ',
  applyLabel: '確定する',
  cancelLabel: 'リセット',
  weekLabel: 'W',
  customRangeLabel: 'Custom Range',
  daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
  monthNames: moment.localeData('ja').monthsShort(),
  firstDay: 0
};

export const MONTH_LABEL = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]

export const TIME_PICKER = [
  {value: '00:00', text: '00:00'}, {value: '00:15', text: '00:15'}, {value: '00:30', text: '00:30'}, {value: '00:45', text: '00:45'},
  {value: '01:00', text: '01:00'}, {value: '01:15', text: '01:15'}, {value: '01:30', text: '01:30'}, {value: '01:45', text: '01:45'},
  {value: '02:00', text: '02:00'}, {value: '02:15', text: '02:15'}, {value: '02:30', text: '02:30'}, {value: '02:45', text: '02:45'},
  {value: '03:00', text: '03:00'}, {value: '03:15', text: '03:15'}, {value: '03:30', text: '03:30'}, {value: '03:45', text: '03:45'},
  {value: '04:00', text: '04:00'}, {value: '04:15', text: '04:15'}, {value: '04:30', text: '04:30'}, {value: '04:45', text: '04:45'},
  {value: '05:00', text: '05:00'}, {value: '05:15', text: '05:15'}, {value: '05:30', text: '05:30'}, {value: '05:45', text: '05:45'},
  {value: '06:00', text: '06:00'}, {value: '06:15', text: '06:15'}, {value: '06:30', text: '06:30'}, {value: '06:45', text: '06:45'},
  {value: '07:00', text: '07:00'}, {value: '07:15', text: '07:15'}, {value: '07:30', text: '07:30'}, {value: '07:45', text: '07:45'},
  {value: '08:00', text: '08:00'}, {value: '08:15', text: '08:15'}, {value: '08:30', text: '08:30'}, {value: '08:45', text: '08:45'},
  {value: '09:00', text: '09:00'}, {value: '09:15', text: '09:15'}, {value: '09:30', text: '09:30'}, {value: '09:45', text: '09:45'},
  {value: '10:00', text: '10:00'}, {value: '10:15', text: '10:15'}, {value: '10:30', text: '10:30'}, {value: '10:45', text: '10:45'},
  {value: '11:00', text: '11:00'}, {value: '11:15', text: '11:15'}, {value: '11:30', text: '11:30'}, {value: '11:45', text: '11:45'},
  {value: '12:00', text: '12:00'}, {value: '12:15', text: '12:15'}, {value: '12:30', text: '12:30'}, {value: '12:45', text: '12:45'},
  {value: '13:00', text: '13:00'}, {value: '13:15', text: '13:15'}, {value: '13:30', text: '13:30'}, {value: '13:45', text: '13:45'},
  {value: '14:00', text: '14:00'}, {value: '14:15', text: '14:15'}, {value: '14:30', text: '14:30'}, {value: '14:45', text: '14:45'},
  {value: '15:00', text: '15:00'}, {value: '15:15', text: '15:15'}, {value: '15:30', text: '15:30'}, {value: '15:45', text: '15:45'},
  {value: '16:00', text: '16:00'}, {value: '16:15', text: '16:15'}, {value: '16:30', text: '16:30'}, {value: '16:45', text: '16:45'},
  {value: '17:00', text: '17:00'}, {value: '17:15', text: '17:15'}, {value: '17:30', text: '17:30'}, {value: '17:45', text: '17:45'},
  {value: '18:00', text: '18:00'}, {value: '18:15', text: '18:15'}, {value: '18:30', text: '18:30'}, {value: '18:45', text: '18:45'},
  {value: '19:00', text: '19:00'}, {value: '19:15', text: '19:15'}, {value: '19:30', text: '19:30'}, {value: '19:45', text: '19:45'},
  {value: '20:00', text: '20:00'}, {value: '20:15', text: '20:15'}, {value: '20:30', text: '20:30'}, {value: '20:45', text: '20:45'},
  {value: '21:00', text: '21:00'}, {value: '21:15', text: '21:15'}, {value: '21:30', text: '21:30'}, {value: '21:45', text: '21:45'},
  {value: '22:00', text: '22:00'}, {value: '22:15', text: '22:15'}, {value: '22:30', text: '22:30'}, {value: '22:45', text: '22:45'},
  {value: '23:00', text: '23:00'}, {value: '23:15', text: '23:15'}, {value: '23:30', text: '23:30'}, {value: '23:45', text: '23:45'},
];

export const REGEX_NAME = /^[a-zA-Z0-9ｧ-ﾝﾞﾟ一-龥 ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]{1,30}$/
